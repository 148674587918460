<template>
  <div class="home">
      <router-link to="/intercityBus" >市际班车</router-link>
      <router-link to="/UARBus" >城乡客运</router-link>
      <router-link to="/cityBus" >城市公交</router-link>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
 a{
     margin: 10px;
 }
</style>
