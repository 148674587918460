<template>
  <router-view/>
</template>

<style>
*{
    margin: 0;
    padding: 0;
}
</style>
